import {
  Button,
  Card,
  CardBody,
  Code,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Form, useLoaderData } from "react-router-dom";
import { useStoreApi } from "reactflow";
import { useQuestProvider } from "../../context/QuestContext";
import useQuest from "../../hooks/quests/useQuest";
import useQuestSubgraphs from "../../hooks/quests/useQuestSubgraphs";
import useUpdateQuestMetaDataAndNodeData from "../../hooks/quests/useUpdateQuestMetaDataAndNodeData";
import useExportRuntimeData from "../../hooks/useExportRuntimeData";
import useInitialNodeTypes from "../../hooks/useInitialNodeTypes";
import { Deploy, deployVersionTypes } from "../../models/api/deploy";
import { QuestWithId } from "../../models/api/quest";
import { NodeData } from "../../models/nodeType";
import FormInput from "../../ui/form/FormInput";
import FormRadio from "../../ui/form/FormRadio";

function QuestDeployVersionButton() {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const { quest, saveQuest } = useQuestProvider();

  const { register, watch, handleSubmit, reset } = useForm<Deploy>({
    defaultValues: useMemo(
      () => ({
        deployVersion: quest?.deployVersion,
        deployVersionType: "patch",
        deployMessage: quest?.deployMessage,
      }),
      [quest]
    ),
    mode: "onSubmit",
  });

  useEffect(
    () =>
      reset({
        deployVersion: quest?.deployVersion,
        deployVersionType: "patch",
        deployMessage: quest?.deployMessage,
      }),
    [quest]
  );

  const store = useStoreApi();

  const { questSubgraphs } = useQuestSubgraphs();
  const initialNodeTypes = useInitialNodeTypes(questSubgraphs);

  const currentDeployVersion = watch("deployVersion", "0.0.0");
  const currentDeployVersionType = watch("deployVersionType");

  const updatedDeployVersion = useMemo(() => {
    if (currentDeployVersion == null) {
      return;
    }

    const [major, minor, patch] = currentDeployVersion.split(".");

    let updatedMajor = Number(major);
    let updatedMinor = Number(minor);
    let updatedPatch = Number(patch);

    if (currentDeployVersionType === "major") {
      updatedMajor += 1;
      updatedMinor = 0;
      updatedPatch = 0;
    }

    if (currentDeployVersionType === "minor") {
      updatedMinor += 1;
      updatedPatch = 0;
    }

    if (currentDeployVersionType === "patch") {
      updatedPatch += 1;
    }

    return `${updatedMajor}.${updatedMinor}.${updatedPatch}`;
  }, [currentDeployVersion, currentDeployVersionType]);

  const { exportQuestNodes } = useExportRuntimeData();

  const toast = useToast();

  const handleUpdate = useCallback(
    ({ deployMessage }: Deploy) => {
      if (quest == null) {
        return;
      }

      const nodes = store.getState().getNodes();
      const edges = store.getState().edges;

      const updatedNodes = nodes.map((node) => {
        const initialNodeType = initialNodeTypes.find(({ nodeName }) => nodeName === node.data.nodeName);
        const jsonSchema = initialNodeType?.nodeData?.templateData?.jsonSchema;

        const {
          data: { nodeData = {} },
        } = node;

        const updatedNodeData: NodeData = {
          ...nodeData,
          templateData: {
            ...nodeData.templateData,
            jsonSchema,
          },
        };

        return {
          ...node,
          data: {
            ...node.data,
            nodeData: updatedNodeData,
          },
        };
      });

      const updatedEdges = edges.slice();

      try {
        saveQuest(
          {
            ...quest,
            data: { nodes: updatedNodes, edges: updatedEdges },
            dataDefinitions: initialNodeTypes,
          },
          exportQuestNodes(updatedNodes, updatedEdges, initialNodeTypes),
          { deployVersion: updatedDeployVersion, deployMessage }
        );

        reset({ deployVersion: updatedDeployVersion, deployVersionType: "patch", deployMessage });

        onClose();
      } catch (error) {
        toast({
          title: "Saving of quest unsuccessful",
          description: (error as Error).message,
          status: "error",
        });
      }
    },
    [saveQuest, exportQuestNodes, reset, onClose, toast, quest, store, initialNodeTypes, updatedDeployVersion]
  );

  if (quest == null) {
    return null;
  }

  return (
    <Popover placement={"left-end"} isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
      <PopoverTrigger>
        <Button color={"green.600"} onClick={onToggle}>
          <Text color={"green.600"} casing={"uppercase"}>
            Save Version
          </Text>
        </Button>
      </PopoverTrigger>

      <Portal>
        <PopoverContent bg={"theme.dark.background"} borderColor={"green.600"} borderWidth={2}>
          <Form onSubmit={handleSubmit(handleUpdate)}>
            <PopoverBody>
              <Stack>
                <Card bg={"theme.dark.background"}>
                  <CardBody>
                    <Stack>
                      <FormRadio
                        {...register("deployVersionType")}
                        values={deployVersionTypes}
                        value={currentDeployVersionType}
                      >
                        <Text color={"white"} casing={"uppercase"} fontWeight={400}>
                          Version <Code>{updatedDeployVersion}</Code>
                        </Text>
                      </FormRadio>

                      <FormInput color={"white"} {...register("deployMessage", { required: true })} borderWidth={1}>
                        <Text color={"white"} casing={"uppercase"} fontWeight={400}>
                          Description
                        </Text>
                      </FormInput>
                    </Stack>
                  </CardBody>
                </Card>

                <Button color={"white"} type={"submit"}>
                  <Text color={"white"} casing={"uppercase"}>
                    Save
                  </Text>
                </Button>
              </Stack>
            </PopoverBody>
          </Form>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export default memo(QuestDeployVersionButton);
