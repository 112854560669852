import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { memo, useCallback, useState } from "react";
import { createMixpanelAnnotation } from "../../api/mixpanel/mixpanel";
import { DeployEnvironment } from "../../api/quests/quests";
import useDeployQuest from "../../hooks/quests/useDeployQuest";
import useQuestOrNull from "../../hooks/quests/useQuestOrNull";
import ConfirmModal from "../modals/ConfirmModal";

interface QuestDeployButtonProps {
  questId: string;
}

function QuestDeployButton({ questId }: QuestDeployButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { quest } = useQuestOrNull(questId);
  const questVersion = quest?.deployVersion ?? "?.?.?";
  const questName = quest?.name ?? "UNKNOWN";

  const [error, setError] = useState<string>("");

  const { isDeploying, deployQuest } = useDeployQuest();

  const isDev = window.location.hostname === "quest-editor.apps.webb.party" || window.location.hostname === "localhost";
  const isStage = window.location.hostname === "quest-editor.apps.webb.black";
  const isProd = window.location.hostname === "quest-editor.apps.webb.game";

  const handleDeployQuest = useCallback(
    (questId: string, environment: DeployEnvironment) => {
      deployQuest(
        { questId, environment },
        {
          onError: (error) => {
            onOpen();

            setError(error.message);
          },
          onSuccess: () => {
            if (environment !== "prod") {
              return;
            }

            createMixpanelAnnotation(questVersion, questName).catch((error) => {
              onOpen();

              setError(error.message);
            });
          },
        }
      );
    },
    [deployQuest, onOpen]
  );

  return (
    <>
      <Popover placement={"left-end"}>
        <PopoverTrigger>
          <Button color={"green.600"} isLoading={isDeploying}>
            <Text color={"green.600"} textTransform={"uppercase"}>
              Deploy To
            </Text>
          </Button>
        </PopoverTrigger>

        <Portal>
          <PopoverContent mx={2} bg={"theme.dark.background"} borderColor={"green.600"} borderWidth={2}>
            <PopoverHeader>
              <Text color={"white"} casing={"uppercase"}>
                Deploy to
              </Text>
            </PopoverHeader>

            <PopoverBody>
              <Stack>
                {!isDev && (
                  <ConfirmModal color={"white"} onConfirm={() => handleDeployQuest(questId, "dev")} title={"DEV"}>
                    <Text color={"white"} casing={"uppercase"}>
                      Are you sure you want to deploy to dev?
                    </Text>
                  </ConfirmModal>
                )}
                {!isStage && (
                  <ConfirmModal color={"white"} onConfirm={() => handleDeployQuest(questId, "stage")} title={"STAGE"}>
                    <Text color={"white"} casing={"uppercase"}>
                      Are you sure you want to deploy to stage?
                    </Text>
                  </ConfirmModal>
                )}
                {!isProd && (
                  <ConfirmModal color={"white"} onConfirm={() => handleDeployQuest(questId, "prod")} title={"PROD"}>
                    <Text color={"white"} casing={"uppercase"}>
                      Are you sure you want to deploy to prod?
                    </Text>
                  </ConfirmModal>
                )}
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>

      <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent bg={"theme.dark.background"} borderColor={"red.800"} borderRadius={0} borderWidth={2}>
          <ModalHeader>
            <Text color={"red.800"} casing={"uppercase"}>
              {error}
            </Text>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody display={"flex"}>
            <Center p={4} flexGrow={1}>
              <Text color={"red.800"} casing={"uppercase"}>
                Quest Deploy Failed
              </Text>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default memo(QuestDeployButton);
