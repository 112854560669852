import { useQuery } from "@tanstack/react-query";
import { QuestSubgraphWithId } from "@worldwidewebb/client-quests";
import { getQuestSubgraphs } from "../../api/quests/questSubgraphs";

export default function useQuestSubgraphs() {
  const {
    isLoading,
    isFetching,
    error,
    data: questSubgraphs,
  } = useQuery<QuestSubgraphWithId[]>({
    queryKey: ["questSubgraphs"],
    queryFn: () => getQuestSubgraphs(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    questSubgraphs,
  };
}
